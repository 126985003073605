<template>
  <footer class="w-full bg-darkblue-500 text-gray-100">
    <div class="container py-12">
      <div class="grid grid-cols-2 md:grid-cols-4 gap-4 px-4">
        <div class="">
          <h1 class="md:text-2xl font-bold mb-4">Prodotti</h1>
          <ul class="text-sm md:text-base font-light uppercase">
            <li>Headphones</li>
          </ul>
        </div>
        <div class="">
          <h1 class="md:text-2xl font-bold mb-4">Assistenza</h1>
          <ul class="text-sm md:text-base font-light uppercase">
            <li>Rimborso</li>
          </ul>
        </div>
        <div class="">
          <h1 class="md:text-2xl font-bold mb-4">Contatti</h1>
          <ul class="text-sm md:text-base font-light uppercase">
            <li>Product Help</li>
          </ul>
        </div>
        <div class="">
          <h1 class="md:text-2xl font-bold mb-4">Seguici</h1>
          <ul class=" font-light uppercase grid grid-cols-4 ">
            <li
              class="w-6 h-6 p-1 md:w-10 md:h-10 md:p-2 md:text-xl text-darkblue-500 bg-white rounded-full flex justify-center items-center mr-3"
            >
              <i class="mdi mdi-instagram"></i>
            </li>
            <li
              class="w-6 h-6 p-1 md:w-10 md:h-10 md:p-2 md:text-xl text-darkblue-500 bg-white rounded-full flex justify-center items-center mr-3"
            >
              <i class="mdi mdi-facebook"></i>
            </li>
            <li
              class="w-6 h-6 p-1 md:w-10 md:h-10 md:p-2 md:text-xl text-darkblue-500 bg-white rounded-full flex justify-center items-center mr-3"
            >
              <i class="mdi mdi-twitter"></i>
            </li>
            <li
              class="w-6 h-6 p-1 md:w-10 md:h-10 md:p-2 md:text-xl text-darkblue-500 bg-white rounded-full flex justify-center items-center mr-3"
            >
              <i class="mdi mdi-whatsapp"></i>
            </li>
          </ul>
        </div>
      </div>
      <div class="text-center text-darkblue-300 text-xs mt-16">
        &copy; 2020 Qubi R&D - All Rights Reserved
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "bottomfooter",

  mounted() {
    //this.$store.dispatch("footer/getOffices");
    //this.$store.dispatch("footer/getSocialNetworks");
  },

  computed: {
    menu: {
      get() {
        let _menu = this.$store.getters["footer/menu"];
        return _menu;
      }
      //set(newValue) {}
    }
  }
};
</script>
<style lang="scss" scoped></style>
