<template>
  <transition name="cart">
    <div
      v-if="showCart"
      class="fixed right-0 top-0 h-screen w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4 bg-white shadow-xl px-8 py-2"
    >
      <div class="container">
        <div class="flex items-baseline mb-4">
          <div class="flex-grow">Carrello</div>
          <button
            class="focus:outline-none text-primary-500"
            @click="SHOW_CART(false)"
          >
            <i class="mdi mdi-close-thick text-2xl"></i>
          </button>
        </div>
        <div class="flex">
          <router-link
            :to="{ path: `/${$route.params.lang}/checkout` }"
            class="btn btn-cta w-full text-center"
          >
            Vai al pagamento <i class="mdi mdi-chevron-right"></i>
          </router-link>
        </div>
        <section
          class="flex items-baseline mt-8 border-b border-gray-400 py-2"
          v-if="cartTotal > 0"
        >
          <p class="font-bold flex-grow">Totale</p>
          <p class="font-bold text-xl">{{ cartTotal | price }}</p>
        </section>
        <section class="mt-4 h-auto relative overflow-y-scroll">
          <cart-card
            v-for="(item, i) in items"
            :key="i"
            :item="item"
          ></cart-card>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

import CartCard from "./CartCard.vue";
export default {
  components: {
    CartCard
  },
  computed: {
    ...mapState("cart", ["showCart"]),
    ...mapGetters("cart", ["items", "cartTotal"])
  },
  methods: {
    ...mapMutations("cart", ["SHOW_CART"])
  }
};
</script>

<style>
.cart-enter-active {
  transition: all 0.3s ease-out;
}
.cart-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.cart-enter, .cart-leave-to
/* .cart-leave-active below version 2.1.8 */ {
  transform: translateX(25%);
  opacity: 0;
}
</style>
