import { Auth } from "aws-amplify";

const state = {
  loadingStatus: "",
  error: null,
  //Use this in case editing user is invoked from page other than single user page
  user: ""
};

const getters = {
  loadingStatus(state) {
    return state.loadingStatus;
  },
  error(state) {
    return state.error;
  },
  user(state) {
    return state.user;
  }
};

const actions = {
  getUser({ commit }, data) {
    commit("SET_USER", data);
  },
  async editUser({ commit }, data) {
    commit("SET_STATUS", "LOADING");
    commit("SET_ERROR", null);

    return new Promise(async (resolve, reject) => {
      let currentUser = await Auth.currentAuthenticatedUser(); //we need to get CognitoUser

      await Auth.updateUserAttributes(currentUser, {
        name: data.name,
        family_name: data.family_name,
        email: data.email
      })
        .then(response => {
          commit("SET_STATUS", "LOADED");
          resolve(response);
        })
        .catch(error => {
          //todo: check response on error
          commit("SET_STATUS", "ERROR");
          commit("SET_ERROR", error.response.data.message);
          reject(error.response.data.message);
        });
    });
  }
};

const mutations = {
  SET_STATUS(state, status) {
    state.loadingStatus = status;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_USER(state, data) {
    state.user = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
