import { API, Auth } from "aws-amplify";

const state = {
  loadingStatus: "",
  error: null,
  users: {}
};

const getters = {
  loadingStatus(state) {
    return state.loadingStatus;
  },
  error(state) {
    return state.error;
  },
  users(state) {
    return state.users;
  }
};

const actions = {
  async getUsers({ commit, dispatch }) {
    commit("SET_STATUS", "LOADING");
    commit("SET_ERROR", null);

    let apiName = "AdminQueries";
    let path = "/listUsers";
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`
      }
    };

    await API.get(apiName, path, myInit)
      .then(async response => {
        let users = [];

        await Promise.all(
          response.Users.map(async userDetails => {
            // For each user getGroups is called
            let userGroups = [];
            await dispatch("getGroups", userDetails.Username).then(groups => {
              groups.Groups.forEach(group => userGroups.push(group.GroupName));
            });

            let userAttributes = [];
            userDetails.Attributes.forEach(
              attr => (userAttributes[attr.Name] = attr.Value)
            );

            const user = {
              username: userDetails.Username,
              attributes: userAttributes,
              enabled: userDetails.Enabled,
              status: userDetails.UserStatus,
              groups: userGroups,
              created: userDetails.UserCreateDate,
              updated: userDetails.UserLastModifiedDate
            };

            users.push(user);
          })
        );
        commit("SET_USERS", users);
        commit("SET_STATUS", "LOADED");
      })
      .catch(error => {
        commit("SET_STATUS", "ERROR");
        commit("SET_ERROR", error.response.data.message || error);
      });
  },
  async getGroups({ commit }, username) {
    let apiName = "AdminQueries";
    let myInit = {
      queryStringParameters: {
        username: username
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`
      }
    };

    return await API.get(apiName, "/listGroupsForUser", myInit).catch(error => {
      commit("SET_ERROR", error.response.data.message || error);
    });
  }
};

const mutations = {
  SET_STATUS(state, status) {
    state.loadingStatus = status;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_USERS(state, data) {
    state.users = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
