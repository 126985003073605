<template>
  <router-view />
</template>
<script>
export default {
  async beforeCreate() {
    await this.$store.dispatch("auth/getCurrentUser");
    await this.$store.dispatch("auth/getCurrentSession");
  }
};
</script>
<style lang="postcss"></style>
