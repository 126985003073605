import { API_URL } from "@/app-axios.js";
//import { setTimeout } from "core-js";

const api_path = "/cart";
const state = {
  items: [],
  showCart: false,
  activeUser: null
};

// getters
const getters = {
  items: state => {
    return state.items;
  },
  badgeCount: state =>
    state.items.reduce((a, i) => {
      return parseInt(a) + parseInt(i.quantity);
    }, 0),
  cartTotal: state =>
    state.items
      .reduce((a, i) => {
        return (
          parseFloat(i.single_unit_price) * parseInt(i.quantity) + parseFloat(a)
        );
      }, 0)
      .toFixed(2)
};

// actions
const actions = {
  getCart({ commit }) {
    commit("SET_STATUS", "LOADING");
    API_URL.get(api_path)
      .then(response => commit("SET_ITEMS", response))
      .catch(error => commit("SET_STATUS", error));
  },
  async addItem({ state, commit }, item) {
    console.log("Aggiungo ITEM ", item);

    let found = await state.items.findIndex(
      i => i.product_id === item.product_id
    );
    console.log("verifico che item non sia presente", found);
    if (found > -1) {
      //let newQty = state.items[found].quantity  + 1;
      console.log("TROVATO! aggiungi quantity", item.quantity);

      commit("ADD_QTY", item);
    } else {
      console.log("NON TROVATO ");
      let nq = { ...item };
      nq.quantity = 1;
      commit("ADD_ITEM", nq);
    }

    // // console.log("add item ", state.items.length, state.showCart);
    if (
      state.items.length == 1 &&
      state.items[0].quantity == 1 &&
      state.showCart == false
    ) {
      commit("SHOW_CART", true);
      setTimeout(() => {
        commit("SHOW_CART", false);
      }, 5000);
    }
  }
};

// mutations
const mutations = {
  SET_STATUS(state, data) {
    // console.log('loadingStatus: ' + data);
    state.loadingStatus = data;
  },

  SET_ITEMS(state, data) {
    state.items = data.data;
  },
  EMPTY_CART(state) {
    state.items = [];
  },
  ADD_ITEM(state, data) {
    data.quantity = 1;
    state.items.push(data);
  },
  // TODO: [AL-15] Remove item from cart
  REMOVE_ITEM(state, id) {
    let i = state.items.findIndex(it => it.product_id == id);
    state.items.splice(i, 1);
  },
  UPDATE_ITEM(state, item) {
    let i = state.items.findIndex(it => it.product_id == item.product_id);
    state.items[i] = item;
  },
  ADD_QTY(state, payload) {
    let i = state.items.findIndex(it => it.product_id == payload.product_id);
    state.items[i].quantity += 1;
  },

  SHOW_CART(state, cartState) {
    state.showCart = cartState;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
