import axios from "axios";

export const API_URL = axios.create({
  baseURL: process.env.API_URL
  //   headers: {
  //     Authorization: 'Bearer 123456',
  //     'Content-Type': 'application/json'
  //   }
});

// Add a request interceptor
API_URL.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
API_URL.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);
