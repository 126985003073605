import { API_URL } from "../../app-axios.js";

const api_path = "/menuItems?lang=en";

const state = {
  items: [
    { label: "Come Funziona", link: "howitworks", items: [] },
    { label: "Accedi", link: "login", items: [] }
  ]
};

// getters
const getters = {
  menu: state => {
    return state.items;
  }
};

// actions
const actions = {
  getMenu({ commit }) {
    API_URL.get(api_path)
      .then(response => commit("SET_MENU", response))
      .catch(error => console.log("error", error));
  }
};

// mutations
const mutations = {
  SET_MENU(state, data) {
    state.items = data.data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
