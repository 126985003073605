<template>
  <div
    class="min-h-6 flex w-full border border-gray-200 bg-gray-100 rounded-lg"
  >
    <button @click="sub" class="w-1/3">
      -
    </button>
    <div class="w-1/3 ">
      <input
        type="number"
        class="w-full h-full text-center font-bold bg-gray-100"
        :value="val"
        @input="$emit('input')"
      />
    </div>
    <button @click="add" class=" w-1/3">
      +
    </button>
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "input"
  },
  data() {
    return {
      val: this.value
    };
  },
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 10
    },
    value: {
      type: Number,
      default: 0
    }
  },
  watch: {
    val: function(n, o) {
      if (n != o) {
        this.$emit("input", n);
      }
    }
  },

  methods: {
    add() {
      if (this.val < this.max) {
        this.val++;
      }
    },
    sub() {
      if (this.val > this.min) {
        this.val--;
      }
    },
    testNumber(n) {
      if (n.target.value <= this.min) {
        this.val = this.min;
      }
      if (n.target.value > this.max) {
        this.val = this.max;
      }
    }
  }
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
