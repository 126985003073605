<template>
  <img
    src="@/_assets/images/hive_logo.svg"
    class="w-20 md:w-24 h-8 px-2 md:px-0 object-contain"
  />
</template>

<script>
export default {};
</script>

<style></style>
