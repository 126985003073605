<template>
  <button class="btn" :class="cta ? 'btn-cta' : null" v-on="$listeners">
    <span v-if="loading" class="flex justify-center items-center">
      <i class="mdi mdi-refresh spin"></i>
    </span>
    <slot v-else />
  </button>
</template>

<script>
export default {
  props: {
    cta: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
  // watch: {
  //   loading: function(o, n) {
  //     console.log("Watch ", o, n);
  //   }
  // }
};
</script>

<style>
.spin {
  animation: spin 2.5s linear 0s infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
