<template>
  <div class="grid grid-cols-6 gap-4 mb-4">
    <div class="col-span-3">
      <img
        v-if="item.product_photo"
        :src="item.product_photo"
        class="w-full h-20 object-cover rounded-md"
      />
      <q-default-img
        v-else
        class="w-full h-20 object-cover rounded-md"
      ></q-default-img>
    </div>
    <div class="col-span-3">
      <strong class=" font-bold block capitalize">{{
        item.product_name
      }}</strong>
      <!-- <div class="flex"> -->
      <!-- <div
        class="text-sm text-gray-500 leading-3"
        v-html="item.product_description"
      ></div> -->
      <span class="font-bold">{{ item.single_unit_price | price }}</span>
    </div>
    <q-step-number
      v-model="item.quantity"
      :min="1"
      class="col-span-3"
      @input="UPDATE_ITEM(item)"
    ></q-step-number>
    <button
      @click="REMOVE_ITEM(item.product_id)"
      class="col-span-3 rounded-md bg-red-300 py-1"
    >
      <i class="mdi mdi-close"></i> Elimina
    </button>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    total() {
      return (
        parseFloat(this.item.single_unit_price) * parseInt(this.item.quantity)
      ).toFixed(2);
    }
  },
  watch: {
    // qty: function(val) {
    //   this.total = (parseFloat(this.item.single_unit_price) * parseInt(val)).toFixed(2);
    // }
    items: function(val) {
      return val;
    }
  },
  methods: {
    ...mapMutations("cart", ["REMOVE_ITEM", "UPDATE_ITEM"]),
    test(v) {
      console.log("CHEEEEECK", v);
    }
  }
};
</script>

<style></style>
