import { Auth } from "aws-amplify";
import { router, store } from "../main.js";

const state = {
  loadingStatus: "",
  error: null,
  errorCode: "",
  userSession: "",
  currentUserData: null
};

const getters = {
  loadingStatus(state) {
    return state.loadingStatus;
  },
  error(state) {
    return state.error;
  },
  errorCode(state) {
    return state.errorCode;
  },
  userSession(state) {
    return state.userSession;
  },
  currentUserData(state) {
    if (state.currentUserData) {
      return state.currentUserData;
    }
  },
  companyId(state) {
    console.log("Company ID ", state.currentUserData);
    if (
      !state?.currentUserData ||
      !state?.currentUserData?.attributes ||
      !state?.currentUserData?.attributes?.profile
    )
      return null;
    let profile = state?.currentUserData?.attributes?.profile?.split("#");
    return profile[1];
  },
  token(state) {
    if (!state.currentUserData) return "";
    return state.currentUserData.signInUserSession.accessToken.jwtToken;
  },
  isMerchant(state) {
    return !!state?.currentUserData?.attributes?.profile;
  },
  isLogged(state) {
    return Boolean(state.currentUserData);
  }
};

const actions = {
  async signIn({ commit }, data) {
    commit("SET_STATUS", "LOADING");
    commit("SET_ERROR", null);
    commit("SET_ERROR_CODE", null);

    try {
      let user = await Auth.signIn(data.email, data.password);
      console.log("USER ", user);
      commit("SET_STATUS", "LOADED");
      commit("SET_USER_DATA", user);

      router.push("home");
    } catch (err) {
      commit("SET_ERROR", err.message || err);
      commit("SET_ERROR_CODE", err.code || "");
    }
  },
  signUp({ commit }, data) {
    commit("SET_STATUS", "LOADING");
    commit("SET_ERROR", null);
    commit("SET_ERROR_CODE", null);

    console.log(data);
    Auth.signUp({
      username: data.email,
      password: data.password,
      attributes: {
        name: data.name,
        family_name: data.family_name
      },
      validationData: [] //optional
    })
      .then(response => {
        console.log(response);
        commit("SET_STATUS", "LOADED");
        store.dispatch("signUp/setEmail", data.email);
        router.push("confirm");
      })
      .catch(err => commit("SET_ERROR", err.message || err));
  },
  confirmSignUp({ commit }, data) {
    console.log("auth confirm: " + data.email + " " + data.code);
    commit("SET_STATUS", "LOADING");
    commit("SET_ERROR", null);
    commit("SET_ERROR_CODE", null);

    Auth.confirmSignUp(data.email, data.code, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true
    })
      .then(() => {
        commit("SET_STATUS", "LOADED");
        router.push("login");
      })
      .catch(err => commit("SET_ERROR", err.message || err));
  },
  resendSignUp({ commit }, data) {
    commit("SET_STATUS", "LOADING");
    commit("SET_ERROR", null);
    commit("SET_ERROR_CODE", null);

    Auth.resendSignUp(data.email)
      .then(() => {
        console.log("code resent successfully");
        commit("SET_STATUS", "LOADED");
      })
      .catch(() => {
        console.log(err => commit("SET_ERROR", err.message || err));
      });
  },
  forgotPassword({ commit }, data) {
    commit("SET_STATUS", "LOADING");
    commit("SET_ERROR", null);

    Auth.forgotPassword(data.email)
      .then(() => {
        commit("SET_STATUS", "LOADED");
        store.dispatch("forgotPassword/setEmail", data.email);
        router.push("reset-password");
      })
      .catch(err => commit("SET_ERROR", err.message || err));
  },
  forgotPasswordSubmit({ commit }, data) {
    commit("SET_STATUS", "LOADING");
    commit("SET_ERROR", null);

    Auth.forgotPasswordSubmit(data.email, data.code, data.newPassword)
      .then(response => {
        commit("SET_STATUS", "LOADED");
        console.log(response);
        router.push("login");
      })
      .catch(err => commit("SET_ERROR", err.message || err));
  },
  logout({ commit }) {
    commit("SET_STATUS", "LOADING");
    commit("SET_ERROR", null);

    Auth.signOut()
      .then(() => {
        commit("SET_STATUS", "LOADED");
        commit("SET_USER_SESSION", null);
        commit("SET_USER_DATA", null);
        router.push({ name: "login" });
      })
      .catch(err => commit("SET_ERROR", err.message || err));
  },
  getCurrentSession({ commit }) {
    Auth.currentSession()
      .then(data => commit("SET_USER_SESSION", data))
      .catch(err => commit("SET_ERROR", err.message || err));
  },
  async getCurrentUser({ commit }) {
    commit("SET_STATUS", "LOADING");
    commit("SET_ERROR", null);
    try {
      let user = await Auth.currentAuthenticatedUser({
        bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });

      commit("SET_STATUS", "LOADED");
      commit("SET_USER_DATA", user);
      let profile = user.currentUserData.attributes.profile.split("#");
      commit("products/SET_COMPANY_ID", profile[1]);
      return user;
    } catch (error) {
      commit("SET_ERROR", error.message || error);
    }
  }
};

const mutations = {
  SET_STATUS(state, status) {
    state.loadingStatus = status;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_ERROR_CODE(state, code) {
    state.errorCode = code;
  },
  SET_USER_SESSION(state, session) {
    state.userSession = session;
  },
  SET_USER_DATA(state, data) {
    state.currentUserData = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
