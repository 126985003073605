import QButton from "@/_components/QButton";
import QDefaultImg from "@/_components/QDefaultImg";
import QStepNumber from "@/_components/QStepNumber";
import Amplify from "@aws-amplify/core";
import "@mdi/font/css/materialdesignicons.min.css";
import { Auth } from "aws-amplify";
import firebase from "firebase/app";
import moment from "moment";
import VCalendar from "v-calendar";
import Vue from "vue";
import Meta from "vue-meta";
import VueRouter from "vue-router";
import Vuex from "vuex";
import routes from "./app-routes";
import StoreResource from "./app-store";
import App from "./App.vue";
import awsconfig from "./aws-exports";
import "./_assets/style/design.css";
import "./_assets/style/main.css";
import "./_assets/style/style.scss";
import Footer from "./_components/Footer/footer.vue";
import Header from "./_components/Header/header.vue";
Amplify.configure(awsconfig);

const configOptions = {
  apiKey: "AIzaSyDBSqyOcD4U4kYnhwcvDm65uTHqOgI3k2Y",
  authDomain: "remoney-24517.firebaseapp.com",
  databaseURL: "https://remoney-24517.firebaseio.com",
  projectId: "remoney-24517",
  storageBucket: "remoney-24517.appspot.com",
  messagingSenderId: "1010876189118",
  appId: "1:1010876189118:web:6e794a06bec16fc802d1ef",
  measurementId: "G-KZ6P9B9658"
};
firebase.initializeApp(configOptions);

export const router = new VueRouter({
  mode: "history",
  routes, // short for `routes: routes`
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // eslint-disable-next-line no-unused-vars
    let user;

    Auth.currentAuthenticatedUser()
      .then(data => {
        if (data && data.signInUserSession) {
          user = data;
        }

        next();
      })
      .catch(e => {
        console.log("%c AAAAAAUTH", "font-size:2rem; color:tomato", e);
        next({
          path: `/${process.env.DEFAULT_LANGUAGE}/login`,
          query: {
            redirect: to.fullPath
          }
        });
      });
  }
  next();
});

Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(Vuex);
Vue.use(VCalendar);

//Vue.prototype.$apirtc = window.apiRTC;

Vue.filter("price", function(v) {
  if (!parseFloat(v) > 0) return "";
  return parseFloat(v).toFixed(2) + " €";
});
moment.locale("it");
Vue.filter("formatDate", function(v) {
  if (!moment(v).isValid()) {
    return v;
  }
  return moment(v).format("LLL");
});

//Global components
Vue.component("app-footer", Footer);
Vue.component("app-header", Header);
Vue.component("QButton", QButton);
Vue.component("QDefaultImg", QDefaultImg);
Vue.component("QStepNumber", QStepNumber);

export const store = new StoreResource().Store(Vuex).store;
window.vm = {};
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
