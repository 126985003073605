import { API, Auth } from "aws-amplify";
import AWS from "aws-sdk";

const state = {
  loadingStatus: "",
  error: null,
  userDetails: {}
};

const getters = {
  loadingStatus(state) {
    return state.loadingStatus;
  },
  error(state) {
    return state.error;
  },
  userDetails(state) {
    return state.userDetails;
  }
};

const actions = {
  async getUser({ commit }, username) {
    commit("SET_STATUS", "LOADING");
    commit("SET_ERROR", null);

    let apiName = "AdminQueries";
    let path = "/getUser";
    let myInit = {
      queryStringParameters: {
        username: username
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`
      }
    };

    await API.get(apiName, path, myInit)
      .then(async response => {
        let userGroups = [];
        await API.get(apiName, "/listGroupsForUser", myInit)
          .then(groups => {
            groups.Groups.forEach(group => userGroups.push(group.GroupName));
          })
          .catch(error => {
            commit("SET_ERROR", error.response.data.message || error);
          });

        let userAttributes = [];
        response.UserAttributes.forEach(
          attr => (userAttributes[attr.Name] = attr.Value)
        );

        let userDetails = {
          username: response.Username,
          attributes: userAttributes,
          enabled: response.Enabled,
          status: response.UserStatus,
          groups: userGroups,
          created: response.UserCreateDate,
          updated: response.UserLastModifiedDate
        };

        commit("SET_USER_DETAILS", userDetails);
        commit("SET_STATUS", "LOADED");
      })
      .catch(error => {
        commit("SET_STATUS", "ERROR");
        commit("SET_ERROR", error.response.data.message || error);
      });
  },
  async enableDisableUser({ commit }, data) {
    commit("SET_STATUS", "LOADING");
    commit("SET_ERROR", null);

    return new Promise(async (resolve, reject) => {
      let apiName = "AdminQueries";
      //data.action can be 'enableUser' or 'disableUser'
      let path = "/" + data.action;
      let myInit = {
        body: {
          username: data.username
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`
        }
      };

      await API.post(apiName, path, myInit)
        .then(response => {
          commit("SET_STATUS", "LOADED");
          resolve(response);
        })
        .catch(error => {
          commit("SET_STATUS", "ERROR");
          commit("SET_ERROR", error.response.data.message);
          reject(error.response.data.message);
        });
    });
  },
  async removeUserFromGroup({ commit }, data) {
    commit("SET_STATUS", "LOADING");
    commit("SET_ERROR", null);

    return new Promise(async (resolve, reject) => {
      let apiName = "AdminQueries";
      let path = "/removeUserFromGroup";
      let myInit = {
        body: {
          username: data.username,
          groupname: data.groupname
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`
        }
      };

      await API.post(apiName, path, myInit)
        .then(response => {
          commit("SET_STATUS", "LOADED");
          resolve(response);
        })
        .catch(error => {
          commit("SET_STATUS", "ERROR");
          commit("SET_ERROR", error.response.data.message);
          reject(error.response.data.message);
        });
    });
  },
  // TODO: Login Using Firebase
  async firebaseLogin() {
    // firebase.auth().languageCode = process.env.DEFAULT_LANGUAGE
  },
  async getIdentityCredentials({ state }) {
    var cognito_creds = (AWS.config.credentials = new AWS.CognitoIdentityCredentials(
      {
        IdentityPoolId: "IDENTITY_POOL_ID",
        Logins: {
          "securetoken.google.com/remoney-24517": state.FirebaseUid
        }
      }
    ));
    console.log("Credential", cognito_creds);
  }
};

const mutations = {
  SET_STATUS(state, status) {
    state.loadingStatus = status;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_USER_DETAILS(state, data) {
    state.userDetails = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
