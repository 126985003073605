<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import SimpleLayout from "@/_layout/SimpleLayout";
export default {
  components: {
    SimpleLayout,
    FullLayout: () => import("@/_layout/FullLayout")
  },
  computed: {
    layout() {
      if (!this.$route.meta.layout) return "SimpleLayout";
      return this.$route.meta.layout + "Layout" || "SimpleLayout";
    }
  },
  mounted() {
    console.log("Monto il layout", this.layout);
  }
};
</script>

<style></style>
