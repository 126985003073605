const state = {
  seo: {
    title: "",
    description: ""
  }
};

// getters
const getters = {
  seo: state => {
    return state.seo;
  }
};

// actions
const actions = {
  //   seo({commit}, data) {
  //     commit('SET_SEO', data)
  //   }
};

// mutations
const mutations = {
  SET_SEO(state, data) {
    state.seo.title = data.title;
    state.seo.description = data.description;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export class SEOResource {
  SEOData(_this) {
    return {
      title: _this.$store.getters["seo/seo"].title,
      meta: [
        {
          name: "description",
          content: _this.$store.getters["seo/seo"].description,
          vmid: "description"
        },
        {
          property: "og:title",
          content: _this.$store.getters["seo/seo"].title,
          // template: chunk => `${chunk} - Base App`, //or as string template: '%s - My page',
          vmid: "og:title"
        },
        {
          property: "og:description",
          content: _this.$store.getters["seo/seo"].description,
          // template: chunk => `${chunk} - Base App`, //or as string template: '%s - My page',
          vmid: "og:description"
        }
      ]
    };
  }
}
