/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:713e8c31-a4e8-4b04-8c22-0663e2d5054e",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_XiMJor9rE",
    "aws_user_pools_web_client_id": "126mnrqqsov8775dbgtlq0tr25",
    "oauth": {
        "domain": "alvin-auth-staging.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://localhost/",
        "redirectSignOut": "https://localhost/",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://xax2z9brfh.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
