import { API_URL } from "@/app-axios";
// import Axios from "axios";

const state = {
  productModel: {
    company_id: "",
    product_name: "",
    product_type: "digital",
    product_photo: "",
    single_unit_price: 0,
    product_description: "",
    service_start_date: new Date(), //"2020-12-05T10:18:36.721096",
    service_end_date: new Date(), //"2020-12-05T15:18:36.721096",
    company_name_slug: "",
    company_name: "",
    remoney_payment_code: "",
    promotion: false,
    published: true,
    product_details: []
  },
  nextKey: null,
  products: []
};
const getters = {
  products: state => {
    if (!state.products || state.products.length == 0) return [];
    return state.products;
  },
  companyId: state => {
    return state.productModel.company_id;
  }
};
const mutations = {
  SET_COMPANY_ID(state, payload) {
    state.productModel.company_id = payload;
  },
  SET_COMPANY_NAME_SLUG(state, payload) {
    state.productModel.company_name_slug = payload;
  },
  SET_COMPANY_NAME(state, payload) {
    state.productModel.company_name = payload;
  },
  SET_REMONEY_PAYMENT_CODE(state, payload) {
    state.productModel.remoney_payment_code = payload;
  },
  SET_PRODUCT_NAME(state, payload) {
    state.productModel.product_name = payload;
  },
  SET_PRODUCT_PHOTO(state, payload) {
    state.productModel.product_photo = payload;
  },
  SET_PRICE(state, payload) {
    state.productModel.single_unit_price = parseFloat(payload);
  },
  SET_PRODUCT_DESCRIPTION(state, payload) {
    state.productModel.product_description = payload;
  },
  SET_SERVICE_START_DATE(state, payload) {
    state.productModel.service_start_date = payload;
  },

  SET_PUBLISHED(state, payload) {
    state.productModel.published = payload;
  },
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  ADD_PRODUCTS(state, products) {
    console.log("Concat ", state.products, products);
    state.products = state.products.concat(products);
  },
  ADD_PRODUCT(state, product) {
    state.products.push(product);
  },
  REMOVE_PRODUCT(state, product) {
    state.products.splice(product, 1);
  },
  SET_NEXT_KEY(state, key) {
    state.nextKey = key;
  }
};
const actions = {
  async loadProductsByMerchant({ commit, rootGetters }, id) {
    try {
      console.log();
      let options = {
        url: "/products",
        method: "get",
        params: {
          company: id
        },
        headers: {
          "aws-access-token": rootGetters["auth/token"]
        }
      };
      console.log("Options ", options);
      let prods = await API_URL(options);
      commit("SET_PRODUCTS", prods.data.content);
      commit("SET_COMPANY_ID", rootGetters["auth/token"]);
      return prods.data.content;
    } catch (error) {
      console.log("Errore loadproduct", JSON.parse(JSON.stringify(error)));
    }
  },
  async loadProducts({ state, commit, rootGetters }, params) {
    try {
      let options = {
        url: "/products",
        method: "get",
        params: {
          published: true,
          size: 8,
          next_key: state.nextKey
        },
        headers: {
          "aws-access-token": rootGetters["auth/token"]
        }
      };
      console.log("Options ", options, params.loadMore);
      let prods = await API_URL(options);
      if (params.loadMore) {
        commit("ADD_PRODUCTS", prods.data.content);
      } else {
        commit("SET_PRODUCTS", prods.data.content);
      }
      console.log("Set next key", prods.data.next_key);
      commit("SET_NEXT_KEY", prods.data.next_key);
      return prods.data.content;
    } catch (error) {
      console.log("Errore loadproduct", error);
    }
  },
  // async uploadProductPhoto() {
  //   API_URL({ url: "", method: "PUT" });
  // },
  async publishProduct({ state, commit, rootState }) {
    console.log("ROOT ", rootState);

    try {
      let companyID = rootState.auth.currentUserData.attributes.profile.split(
        "#"
      )[1];
      commit("SET_COMPANY_ID", companyID);
      commit(
        "SET_COMPANY_NAME_SLUG",
        rootState.createstore.company.company_name_slug
      );
      commit("SET_COMPANY_NAME", rootState.createstore.company.name);
      commit(
        "SET_REMONEY_PAYMENT_CODE",
        rootState.createstore.company.remoney_payment_code
      );
      let option = {
        url: `/products`,
        method: "POST",
        data: state.productModel,
        headers: {
          "aws-access-token":
            rootState.auth.currentUserData.signInUserSession.accessToken
              .jwtToken,
          "Content-type": "application/json"
        }
      };
      //console.log("TOKEN ", option);
      let prod = await API_URL(option);
      //console.log("Prod ", prod);
      return prod;
    } catch (error) {
      console.log("Stack errore ", error.stack);
      console.log(
        "Errore Creazione prodotto",
        JSON.parse(JSON.stringify(error))
      );
    }
  },
  resetModel({ commit }) {
    commit("SET_COMPANY_ID", "");
    commit("SET_PRODUCT_NAME", "");
    commit("SET_PRODUCT_PHOTO", "");
    commit("SET_PRICE", 0.0);
    commit("SET_PRODUCT_DESCRIPTION", "");
    commit("SET_COMPANY_NAME_SLUG", "");
    commit("SET_COMPANY_NAME", "");
    commit("SET_REMONEY_PAYMENT_CODE", "");
    commit("SET_SERVICE_START_DATE", new Date());

    //commit("SET_PUBLISHED","")
  },
  deleteProduct({ state, commit, rootGetters }, id) {
    try {
      API_URL({
        url: `products/${id}`,
        method: "DELETE",
        headers: {
          "aws-access-token": rootGetters["auth/token"]
        }
      }).then(res => {
        console.log("Record Deleted ", res);
        let idx = state.products.findIndex(p => p.product_id == id);
        if (idx > 0) {
          console.log("Tovato record, ", idx);
          commit("REMOVE_PRODUCT", id);
        }
      });
    } catch (error) {
      console.log("Error delete ", error);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
