import I18n from "./_components/_i18n/I18n";

const default_lang = process.env.DEFAULT_LANGUAGE;

//import routes from "./router/routes";

let routes = [
  {
    path: "/:lang",
    component: I18n,
    children: [
      {
        path: "login",
        component: () => import("./views/User/Login/Login.vue"),
        name: "login",
        meta: {
          requiresAuth: false,
          layout: "Simple"
        }
      },
      {
        path: "register",
        component: () => import("./views/User/Register/SignUp.vue"),
        name: "signUp",
        meta: {
          requiresAuth: false,
          layout: "Simple"
        }
      },
      {
        path: "about",
        component: () => import("./views/About/About.vue"),
        name: "about",
        meta: {
          requiresAuth: false,
          layout: "Simple"
        }
      },
      {
        path: "confirm",
        redirect: "home"
        // component: () =>
        //   import("./views/User/Register/ConfirmEmail/confirmEmail.vue"),
        // name: "confirmEmail",
        // meta: {
        //   requiresAuth: false,
        //   layout: "Simple"
        // }
      },
      {
        path: "forgot-password",
        component: () =>
          import(
            "./views/User/Login/_components/ForgotPassword/forgotPassword.vue"
          ),
        name: "forgotPassword",
        meta: {
          requiresAuth: false,
          layout: "Simple"
        }
      },
      {
        path: "reset-password",
        component: () =>
          import(
            "./views/User/Login/_components/ResetPassword/resetPassword.vue"
          ),
        name: "resetPassword",
        meta: {
          requiresAuth: false,
          layout: "Simple"
        }
      },
      {
        path: "store",
        component: () => import("./views/Store/Store.vue"),
        children: [
          { path: "", redirect: "create" },
          {
            path: "admin",
            component: () => import("./views/Store/AdminStore.vue"),
            children: [
              { path: "", redirect: "editstore" },
              {
                path: "editstore",
                component: () => import("./views/Store/EditStore.vue"),

                meta: {
                  requiresAuth: true,
                  layout: "Full"
                }
              },
              {
                path: "addproduct",
                component: () => import("./views/Products/AddProduct.vue"),
                meta: {
                  requiresAuth: true,
                  layout: "Full"
                }
              },
              {
                path: "editproducts",
                component: () => import("./views/Products/EditProducts.vue"),
                meta: {
                  requiresAuth: true,
                  layout: "Full"
                }
              },
              {
                path: "editproducts/:id",
                component: () => import("./views/Products/EditProduct.vue"),
                meta: {
                  requiresAuth: true,
                  layout: "Full"
                }
              },
              {
                path: "orders-received",
                component: () => import("./views/Store/OrdersReceived.vue"),
                meta: {
                  requiresAuth: true,
                  layout: "Full"
                }
              },
              {
                path: "orders-sent",
                component: () => import("./views/Store/OrdersSent.vue"),
                meta: {
                  requiresAuth: true,
                  layout: "Full"
                }
              }
            ]
          },

          {
            path: "create",
            component: () => import("./views/Store/CreateStore.vue"),
            children: [
              { path: "", redirect: "1" },
              {
                path: "1",
                name: "step1",
                component: () => import("./views/Store/_components/Step1.vue"),
                meta: {
                  requiresAuth: false,
                  layout: "Simple"
                }
              },
              {
                path: "2",
                name: "step2",
                component: () => import("./views/Store/_components/Step2.vue"),
                meta: {
                  requiresAuth: false,
                  layout: "Simple"
                }
              },
              {
                path: "3",
                name: "step3",
                component: () => import("./views/Store/_components/Step3.vue"),
                meta: {
                  requiresAuth: false,
                  layout: "Simple"
                }
              },
              {
                path: "4",
                name: "step4",
                component: () => import("./views/Store/_components/Step4.vue"),
                meta: {
                  requiresAuth: false,
                  layout: "Simple"
                }
              },
              {
                path: "5",
                name: "step5",
                component: () => import("./views/Store/_components/Step5.vue"),
                meta: {
                  requiresAuth: false,
                  layout: "Simple"
                }
              },
              {
                path: "6",
                name: "step6",
                component: () => import("./views/Store/_components/Step6.vue"),
                meta: {
                  requiresAuth: false,
                  layout: "Simple"
                }
              }
            ]
          },
          {
            path: ":marchantslug",
            component: () => import("./views/Store/FrontStore.vue"),
            meta: { requiresAuth: false, layout: "Full" }
          }
          // {
          //   path: ":marchantslug/product/:id",
          //   component: () => import("./views/Products/Product.vue"),
          //   meta: { requiresAuth: false, layout: "Full" }
          // }
        ]
      },
      {
        path: "",
        redirect: "home"
      },
      {
        path: "home",
        component: () => import("./Home/home.vue"),
        name: "home",
        meta: {
          requiresAuth: false,
          layout: "Full"
        }
      },

      {
        path: "contact",
        component: () => import("./Contact/contact.vue"),
        name: "contact",
        meta: {
          requiresAuth: false,
          layout: "Full"
        }
      },
      {
        path: "checkout",
        component: () => import("./views/Cart/Checkout.vue"),
        name: "checkout",
        meta: {
          requiresAuth: true,
          layout: "Full"
        }
      },
      {
        path: "products/:id",
        component: () => import("./views/Products/Product.vue"),
        name: "product",
        meta: {
          requiresAuth: false,
          layout: "Full"
        }
      },
      {
        path: "projects/:projectSlug",
        component: () => import("./Project/project.vue"),
        name: "project",
        meta: {
          requiresAuth: true,
          layout: "Full"
        }
      },
      {
        path: "live",
        component: () => import("./views/Products/Live.vue"),
        name: "live",
        meta: {
          requiresAuth: false,
          layout: "Full"
        }
      },
      {
        path: "legal",
        component: () => import("./views/Legal/Legal.vue"),
        children: [
          { path: "", redirect: "privacy" },
          {
            path: "privacy",
            component: () => import("./views/Legal/Privacy.vue"),
            meta: {
              requiresAuth: false,
              layout: "Simple"
            }
          },
          {
            path: "terms",
            component: () => import("./views/Legal/Terms.vue"),
            meta: {
              requiresAuth: false,
              layout: "Simple"
            }
          },
          {
            path: "merchant",
            component: () => import("./views/Legal/Merchant.vue"),
            meta: {
              requiresAuth: false,
              layout: "Simple"
            }
          }
        ]
      },
      {
        path: "user",
        component: () => import("@/views/User/Admin/Admin.vue"),

        children: [
          { path: "", redirect: "profile" },
          {
            path: "profile",
            component: () => import("@/views/User/Admin/Profile.vue"),
            meta: {
              requiresAuth: true,
              layout: "Full"
            }
          },
          {
            path: "orders",
            component: () => import("@/views/User/Admin/Orders.vue"),
            meta: {
              requiresAuth: true,
              layout: "Full"
            }
          }
        ]
      },
      // {
      //   path: "users",
      //   component: () => import("./Users/users.vue"),
      //   name: "users"
      // },
      // {
      //   path: "users/:username",
      //   component: () => import("./Users/_components/User/User.vue"),
      //   name: "user"
      // }

      {
        path: "*",
        component: () => import("./PageNotFound/pageNotFound.vue"),
        name: "404",
        meta: {
          requiresAuth: false,
          layout: "Full"
        }
      }
    ]
  },
  { path: "*", redirect: "/" + default_lang + "/home" }
];

export default routes;
