const state = {
  action: "",
  actionTitle: "",
  parameter: ""
};

const getters = {
  action(state) {
    return state.action;
  },
  actionTitle(state) {
    return state.actionTitle;
  },
  parameter(state) {
    return state.parameter;
  }
};

const actions = {
  setAction({ commit }, data) {
    commit("SET_ACTION", data);
  },
  setActionTitle({ commit }, data) {
    commit("SET_ACTION_TITLE", data);
  },
  setParameter({ commit }, data) {
    commit("SET_PARAMETER", data);
  }
};

const mutations = {
  SET_ACTION(state, data) {
    state.action = data;
  },
  SET_ACTION_TITLE(state, data) {
    state.actionTitle = data;
  },
  SET_PARAMETER(state, data) {
    state.parameter = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
