<template>
  <div class="relative">
    <ul
      class="absolute right-0 bg-gray-100 h-auto min-w-48 py-4 shadow-2xl rounded-md text-darkblue-500 z-100 text-sm"
    >
      <li class="py-3 px-4 border-b border-gray-300">
        <h2>
          Ciao,
          <span class="font-bold">{{ currentUserData.attributes.name }}</span>
        </h2>
        <button
          @click="logout"
          class="block w-full bg-gray-200 py-1 mt-2 rounded-sm"
        >
          Esci
        </button>
      </li>

      <router-link
        :to="`/${$route.params.lang}/store/admin/editstore`"
        v-if="$store.getters['auth/isMerchant']"
        tag="li"
        class="py-1 px-4 border-b border-gray-200 hover:bg-gray-200 flex justify-center items-center"
        @click="showSubmenu = false"
      >
        <span class="flex-grow">Profilo</span>
        <i class="mdi mdi-account text-xl mr-3"></i>
      </router-link>
      <template v-if="!$store.getters['auth/isMerchant']">
        <router-link
          :to="`/${$route.params.lang}/user/profile`"
          tag="li"
          class="py-1 px-4 border-b border-gray-200 hover:bg-gray-200 flex justify-center items-center"
          @click="showSubmenu = false"
        >
          <span class="flex-grow">Gestione Profilo</span>
          <i class="mdi mdi-account text-xl mr-3"></i>
        </router-link>

        <router-link
          :to="`/${$route.params.lang}/user/orders`"
          tag="li"
          class="py-1 px-4 border-b border-gray-200 hover:bg-gray-200 flex justify-center items-center"
        >
          <span class="flex-grow">Visualizza Ordini</span>
          <i class="mdi mdi-package-variant-closed text-xl mr-3"></i>
        </router-link>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("auth", ["currentUserData"])
  },
  methods: {
    logout() {
      // this.showSubmenu = false;
      this.$store.dispatch("auth/logout");
    }
  }
};
</script>

<style></style>
