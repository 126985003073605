import router from "@/app-routes";
import aws_config from "@/aws-exports";
import AWS from "aws-sdk";
import awsV4 from "aws-v4";
import Axios from "axios";
import firebase from "firebase";

const state = {
  storeType: "food",
  storeName: "",
  name: "",
  surname: "",
  email: "",
  password: "",
  companyname: "",
  vatnumber: "",
  cashback: "",
  legaladdress: "",
  operativeaddress: "",
  phonenumber: "",
  otp: "",
  confirmationResult: null,
  user: null,
  error: null,
  company: {},
  storeCategories: [
    { value: "groceries", label: "Alimentari" },
    { value: "insurance", label: "Assicurazione" },
    { value: "furnishings", label: "Arredamento" },
    { value: "online_shopping", label: "Acquisti online" },
    { value: "publishing", label: "Editoria" },
    { value: "electronics", label: "Elettronica" },
    { value: "fashion ", label: "Moda" },
    { value: "wellness", label: "Benessere" },
    { value: "free_time", label: "Tempo Libero" },
    { value: "tourism", label: "Turismo" },
    { value: "other", label: "Altro" },
    { value: "software", label: "Software" },
    { value: "organizations", label: "Organizzazione" },
    { value: "stationery", label: "Cartoleria" },
    { value: "real_estate", label: "Immobiliare" },
    { value: "diy", label: "Fai da te" },
    { value: "cars", label: "Auto" },
    { value: "food", label: "Cibo" },
    { value: "restaurant", label: "Ristorazione" },
    { value: "bar", label: "Bar" },
    { value: "pub", label: "Pub" },
    { value: "library", label: "Libreria" },
    { value: "cinema", label: "Cinema" },
    { value: "theatre", label: "Teatro" },
    { value: "music", label: "Musica" },
    { value: "art", label: "Arte e Cultura" },
    { value: "fitness", label: "Fitness" },
    { value: "consulting", label: "Consulenza" }
  ]
};
const getters = {
  phonenumber: state => {
    return "+39" + state.phonenumber;
  }
};

const actions = {
  async loginWithPhone({ commit, getters }, w) {
    try {
      //let appVerifier = w.recaptchaVerifier;
      let confirmationResult = await firebase
        .auth()
        .signInWithPhoneNumber(getters.phonenumber, w);
      commit("SET_CONFIRMATIONRESULT", confirmationResult);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async confirmOtp({ state, commit, dispatch }) {
    let user = state.confirmationResult.confirm(state.otp);
    commit("SET_USER", user);
    console.log("User confirmed ", user);
    let credentials = firebase.auth.PhoneAuthProvider.credential(
      state.confirmationResult.verificationId,
      state.otp
    );
    firebase.auth().signInWithCredential(credentials);

    var firebase_uid, aws_access_key, aws_secret_key, aws_session_token;

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdToken().then(idToken => {
          firebase_uid = user.uid;

          AWS.config.region = aws_config.aws_project_region;

          var params = {
            IdentityPoolId: process.env.REMONEY_IDENTITY_POOL,
            Logins: {
              "securetoken.google.com/remoney-24517": idToken
            }
          };

          AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);
          console.log("Credentials ", AWS.config.credentials);
          var cognitoidentity = new AWS.CognitoIdentity();
          console.log("Cognito Identity", cognitoidentity);
          cognitoidentity.getId(params, function(err, data) {
            if (err) {
              console.log(err, err.stack);
            } else {
              var params = {
                IdentityId: data.IdentityId,
                Logins: {
                  "securetoken.google.com/remoney-24517": idToken
                }
              };
              cognitoidentity.getCredentialsForIdentity(params, function(
                err,
                data
              ) {
                if (err) {
                  console.log(err, err.stack);
                } else {
                  aws_access_key = data.Credentials.AccessKeyId;
                  aws_secret_key = data.Credentials.SecretKey;
                  aws_session_token = data.Credentials.SessionToken;
                  console.log(
                    "Identity Before Sign",
                    firebase_uid,
                    aws_access_key,
                    aws_secret_key,
                    aws_session_token
                  );
                  dispatch("aws_sign", {
                    uid: firebase_uid,
                    access_key: aws_access_key,
                    secret_key: aws_secret_key,
                    session_token: aws_session_token
                  });
                }
              });
            }
          });
        });
      }
    });
  },
  async aws_sign({ state, getters, commit }, d) {
    console.log("Endpoint", process.env.REMONEY_ENDPOINT);
    console.log("SIGN METHOD");
    console.log("firebase_uid", d.uid);
    console.log("aws_access_key", d.access_key);
    console.log("aws_secret_key", d.secret_key);
    console.log("aws_session_token", d.session_token);

    let body_params = {
      external_user_id: d.uid,
      passcode: state.password,
      phone_number: getters.phonenumber,
      last_name: state.surname,
      first_name: state.name,
      name: state.name
    };

    const signedRequest = awsV4
      .newClient({
        accessKey: d.access_key,
        secretKey: d.secret_key,
        sessionToken: d.session_token,
        region: aws_config.aws_project_region,
        endpoint: process.env.REMONEY_ENDPOINT
      })
      .signRequest({
        method: "POST",
        path: "/users",
        headers: {
          "Content-Type": "application/json"
        },
        queryParams: {},
        body: body_params
      });

    console.log("AWS Signed Request", body_params);

    const options = {
      url: signedRequest.url,
      method: "post",
      headers: signedRequest.headers,
      data: body_params
    };
    try {
      let aws_login = await Axios(options);
      console.log("Aws Login ", aws_login);
      let business_param = {
        name: state.storeName,
        business_name: state.storeName,
        legal_address: {
          country: "IT",
          district: "BA",
          city: "Bari",
          street_name: "Via Calefati",
          street_number: "72",
          zip_code: "70123"
        },
        email: state.email,
        company_phone_number: getters.phonenumber,
        vat_number: state.vatnumber,
        cashback: state.cashback,
        iban_code: "ITV0000000000000000002",
        business_category: state.storeType,
        store_type: "online",
        visible: true
      };

      let utb = awsV4
        .newClient({
          accessKey: d.access_key,
          secretKey: d.secret_key,
          sessionToken: d.session_token,
          region: aws_config.aws_project_region,
          endpoint: process.env.REMONEY_ENDPOINT
        })
        .signRequest({
          method: "PUT",
          //url: `${process.env.REMONEY_ENDPOINT}/users/upgrade/${d.uid}`,
          path: `/users/upgrade/${d.uid}`,
          headers: {
            "Content-Type": "application/json"
          },
          queryParams: {},
          body: business_param
        });
      let utb_options = {
        url: utb.url,
        method: "PUT",
        headers: utb.headers,
        data: business_param
      };
      let upgradeToBusiness = await Axios(utb_options);
      console.log("Upgrade To Business ", upgradeToBusiness);

      let remoney_payment_code = upgradeToBusiness.data.internal_user_id;
      console.log("Remoney Payment Code ", remoney_payment_code);
      let createHiveStore = await Axios({
        url: `https://sn8t2n7xf9.execute-api.eu-central-1.amazonaws.com/staging/companies/register`,
        method: "POST",
        data: {
          name: state.storeName,
          company_email: state.email,
          company_phone_number: getters.phonenumber,
          industry_type: state.storeType,
          company_vat_num: state.vatnumber,
          company_address: {
            city: "Bari",
            country: "Italy",
            latitude: "45.461023",
            longitude: "9.184576",
            street_name: "Via Torino",
            street_number: "20"
          },
          billing_address: {
            city: "Bari",
            country: "Italy",
            latitude: "45.461023",
            longitude: "9.184576",
            street_name: "Via Torino",
            street_number: "21"
          },
          remoney_payment_code: remoney_payment_code,
          user_name: state.name,
          user_surname: state.surname,
          user_password: state.password
        }
      });

      console.log("Hive Store ", createHiveStore);
      if (createHiveStore.status == 200) {
        router.push(`/${process.env.DEFAULT_LANGUAGE}/store/create/6`);
      }
      //dispatch("upgradeToBusiness", aws_login.data);
    } catch (err) {
      console.log("Errore AXIOS", err, err.response);
      commit("SET_ERROR", err);
    }
  },
  async loadStore({ commit, rootGetters }) {
    console.log(
      "%cCompany Id before send",
      "color:tomato;font-size:1.3rem",
      rootGetters["auth/companyId"]
    );
    try {
      let comp = await Axios({
        url: `${process.env.API_URL}/companies/ids/${rootGetters["auth/companyId"]}`
      });
      console.log("%cCompany loaded", "color:red", comp.data);
      let company = comp.data;
      if (!company.company_details) {
        company.company_details = [
          {
            attribute_name: "presentazione",
            attribute_value: ""
          },
          {
            attribute_name: "altro",
            attribute_value: ""
          }
        ];
      }
      commit("SET_COMPANY", company);
      commit("products/SET_COMPANY_ID", comp.data.company_id, { root: true });
    } catch (error) {
      console.log("Errore load store ", error);
    }
  }

  // async updateStore({commit,rootGetters}){

  // }
};

const mutations = {
  SET_STORETYPE(state, payload) {
    state.storeType = payload;
  },
  SET_STORENAME(state, payload) {
    state.storeName = payload;
  },
  SET_NAME(state, payload) {
    state.name = payload;
  },
  SET_SURNAME(state, payload) {
    state.surname = payload;
  },
  SET_EMAIL(state, payload) {
    state.email = payload;
  },
  SET_PASSWORD(state, payload) {
    state.password = payload;
  },
  SET_COMPANYNAME(state, payload) {
    state.companyname = payload;
  },
  SET_VATNUMBER(state, payload) {
    state.vatnumber = payload;
  },
  SET_CASHBACK(state, payload) {
    state.cashback = payload;
  },
  SET_LEGALADDRESS(state, payload) {
    state.legaladdress = payload;
  },
  SET_OPERATIVEADDRESS(state, payload) {
    state.operativeaddress = payload;
  },
  SET_PHONENUMBER(state, payload) {
    state.phonenumber = payload;
  },
  SET_CONFIRMATIONRESULT(state, payload) {
    console.log("Confirmation Result done ", payload);
    state.confirmationResult = payload;
  },
  SET_OTP(state, payload) {
    state.otp = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_COMPANY(state, payload) {
    state.company = payload;
  },
  SET_COMPANY_COVER_PHOTO(state, payload) {
    state.company.company_header_promotion = payload;
  },
  SET_COMPANY_PROFILE_PHOTO(state, payload) {
    state.company.company_logo = payload;
  },
  SET_ERROR(state, payload) {
    state.error = payload;
  },
  SET_PRESENTATION(state, payload) {
    let pres = state.company.company_details.filter(
      cd => cd.attribute_name === "presentazione"
    )[0];
    pres.attribute_value = payload.value;
  },
  SET_OTHER(state, payload) {
    let oth = state.company.company_details.filter(
      cd => cd.attribute_name === "altro"
    )[0];
    oth.attribute_value = payload.value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
