import createPersistedState from "vuex-persistedstate";
import seo from "./app-seo.js";
import home from "./Home/home.js";
import users from "./Users/users.js";
import user from "./Users/_components/User/user.js";
import addToGroup from "./Users/_components/User/_components/AddToGroup/addToGroup.js";
import confirmationDialog from "./Users/_components/User/_components/ConfirmationDialog/confirmationDialog.js";
import editUser from "./Users/_components/User/_components/EditUser/editUser.js";
import products from "./views/Products/products";
import createstore from "./views/Store/store.js";
import forgotPassword from "./views/User/Login/_components/ForgotPassword/forgotPassword.js";
import signUp from "./views/User/Register/signUp.js";
import cart from "./_components/Cart/cart";
import footer from "./_components/Footer/footer.js";
import header from "./_components/Header/header.js";
import auth from "./_utils/auth.js";

export default class StoreResource {
  Store(Vuex) {
    return {
      store: new Vuex.Store({
        modules: {
          seo,
          auth,
          header,
          footer,
          signUp,
          forgotPassword,
          home,
          products,
          users,
          user,
          editUser,
          addToGroup,
          confirmationDialog,
          cart,
          createstore
        },
        plugins: [createPersistedState({ paths: ["cart"] })]
      })
    };
  }
}
