import { API, Auth } from "aws-amplify";

const state = {
  loadingStatus: "",
  error: null,
  groupsDropdown: [
    // {text: 'SuperAdmins', value: 'Superadmins'},
    // {text: 'Editors', value: 'Editors'}
    // {text: 'Supervisor', value: 'Supervisor'},
    // {text: 'Admin', value: 'Admin'},
    // {text: 'CommercialClerk', value: 'CommercialClerk'},
    // {text: 'AmbulanceDealer', value: 'AmbulanceDealer'},
    // {text: 'DeliveryDriver', value: 'DeliveryDriver'},
    // {text: 'SalesPromoter', value: 'SalesPromoter'}
    { text: "Admin", value: "Admin" },
    { text: "Base", value: "Base" }
  ]
};

const getters = {
  loadingStatus(state) {
    return state.loadingStatus;
  },
  error(state) {
    return state.error;
  },
  groupsDropdown(state) {
    return state.groupsDropdown;
  }
};

const actions = {
  addUserToGroup({ commit }, data) {
    commit("SET_STATUS", "LOADING");
    commit("SET_ERROR", null);

    return new Promise(async (resolve, reject) => {
      let apiName = "AdminQueries";
      let path = "/addUserToGroup";
      let myInit = {
        body: {
          username: data.username,
          groupname: data.selectedGroup
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`
        }
      };

      await API.post(apiName, path, myInit)
        .then(response => {
          commit("SET_STATUS", "LOADED");
          resolve(response);
        })
        .catch(error => {
          commit("SET_STATUS", "ERROR");
          commit("SET_ERROR", error.response.data.message);
          reject(error.response.data.message);
        });
    });
  }
  //// listGroups currently does not exist :)
  // async getGroups({commit}) {
  //     let apiName = 'AdminQueries';
  //     let myInit = {
  //         headers: {
  //             'Content-Type': 'application/json',
  //             Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
  //         }
  //     };
  //
  //     return await API.get(apiName, '/listGroups', myInit)
  //         .then(groups => {
  //             console.log(groups)
  //         })
  //         .catch(error => {
  //             commit('SET_ERROR', error.response.data.message || error);
  //         });
  // }
};

const mutations = {
  SET_STATUS(state, status) {
    state.loadingStatus = status;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_GROUPS_DROPDOWN(state, data) {
    state.groupsDropdown = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
