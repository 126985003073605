import { API_URL } from "../app-axios.js";

const api_path = "/v1/bpi/currentprice.json";

const state = {
  seo: {
    title: "Hive",
    description: "HiveShop Home"
  },
  items: [],
  loadingStatus: ""
};

// getters
const getters = {
  items: state => {
    return state.items;
  },
  loadingStatus: state => {
    return state.loadingStatus;
  },
  seo: state => {
    return state.seo;
  }
};

// actions
const actions = {
  seo({ commit }) {
    commit("seo/SET_SEO", state.seo, { root: true });
  },
  setStatus({ commit }) {
    commit("SET_STATUS", "LOADING");
    API_URL.get(api_path)
      .then(
        response => (
          commit("ADD_ITEM", response.data),
          commit("SET_STATUS", "LOADED"),
          setTimeout(function() {
            commit("SET_STATUS", "READY");
          }, 1000)
        )
      )
      .catch(() => commit("SET_STATUS", "ERROR"));
  }
};

// mutations
const mutations = {
  // SET_SEO (state, data) {
  //   data.seo.title = Math.random()*1000//data.seo.title;
  //   data.seo.description = Math.random()*1000//data.seo.description;
  // },
  SET_STATUS(state, value) {
    // console.log('loadingStatus: ' + value);
    state.loadingStatus = value;
  },
  ADD_ITEM(state, data) {
    state.items.push(data.disclaimer);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
