const state = {
  email: ""
};

const getters = {
  email(state) {
    return state.email;
  }
};

const actions = {
  setEmail({ commit }, email) {
    commit("SET_EMAIL", email);
  }
};

const mutations = {
  SET_EMAIL(state, data) {
    state.email = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
