<template>
  <div class="fixed w-screen z-50 top-0">
    <header class="w-full bg-white shadow-md h-20">
      <div class="container flex items-center py-4 h-full">
        <router-link to="/"><logo /></router-link>
        <nav class="flex-grow ">
          <ul class="hidden md:flex justify-end items-baseline">
            <li
              class="mr-4 font-medium cursor-pointer"
              v-if="!!currentUserData"
            >
              <span>
                <span
                  @click="showSubmenu = !showSubmenu"
                  v-on-clickaway="hideusermenu"
                  ><i class="mdi mdi-account-outline text-2xl text-gray-500"></i
                ></span>
                <user-menu v-if="showSubmenu" />
              </span>
            </li>
            <li class="mr-4 font-medium" v-else>
              <router-link
                :to="{ path: '/' + $route.params.lang + '/login' }"
                class="px-2"
                >Accedi</router-link
              >

              <router-link
                :to="{ path: '/' + $route.params.lang + '/register' }"
                class="px-2"
                v-if="!$store.getters['auth/isMerchant']"
                >Registrati</router-link
              >
            </li>
          </ul>
        </nav>
        <span>
          <router-link
            class="btn btn-cta"
            :to="{ path: `/${$route.params.lang}/store/create` }"
            v-if="!$store.getters['auth/isMerchant']"
          >
            Crea il tuo negozio
          </router-link>
        </span>
        <span>
          <span
            class="relative px-4 py-4 rounded-full hover:bg-gray-100 hover:shadow-md cursor-pointer"
            @click="SHOW_CART(true)"
            v-on-clickaway="hideCart"
          >
            <transition name="cartUpdate">
              <span
                class="absolute text-xs bg-secondary-500 text-white w-4 h-4 flex items-center justify-center ml-2 p-2 rounded-full"
                >{{ badgeCount }}</span
              >
            </transition>
            <i class="mdi mdi-cart-plus text-xl text-gray-500"></i>
          </span>
        </span>
        <cart class="z-100" />
        <!-- <button @click="logout" class="">
          Logout
        </button> -->
      </div>
    </header>
    <!-- <div class="bg-gray-500  py-4 w-full z-10">
      <div class="container w-full flex items-baseline">
        <button
          class="py-2 focus:outline-none whitespace-no-wrap"
          @click="showAdvanced = !showAdvanced"
          v-on-clickaway="hideadvancedsearch"
        >
          <span>{{ storeCategories[activeCategory].label }}</span>
          <i
            class="mdi "
            :class="showAdvanced ? 'mdi-chevron-down' : 'mdi-chevron-right'"
          ></i>
        </button>
        <div
          class="border-gray-700 bg-white ml-6 py-2 px-4 rounded-sm flex flex-grow"
        >
          <i class="mdi mdi-magnify text-xl text-gray-400"></i>
          <input class=" focus:outline-none flex-grow px-6" />
        </div>
      </div>
    </div> -->
    <!-- <transition name="advanced">
      <div class="w-full bg-white shadow-md z-0" v-if="showAdvanced">
        <div class="container py-4">
          <ul class="grid grid-cols-3 md:grid-cols-4 gap-2">
            <li
              class="w-full cursor-pointer whitespace-no-wrap text-sm md:text-base"
              v-for="(sc, i) in storeCategories"
              :key="i"
              @click="activeCategory = i"
            >
              <span class="text-primary-500 mr-2 ">
                <i
                  class="mdi "
                  :class="
                    activeCategory == i ? 'mdi-circle' : 'mdi-circle-outline'
                  "
                ></i
              ></span>
              {{ sc.label }}
            </li>
          </ul>
        </div>
      </div>
    </transition> -->
  </div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
import Logo from "@/_components/Logo";
import Cart from "@/_components/Cart/Cart.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import UserMenu from "../UserMenu.vue";

export default {
  name: "topmenu",

  mixins: [clickaway],
  // mounted() {
  //     this.$store.dispatch('header/getMenu');
  // },
  data() {
    return {
      showAdvanced: false,
      showSubmenu: false,
      activeCategory: 0,
      locale: process.env.DEFAULT_LANGUAGE
    };
  },
  components: {
    Logo,
    Cart,
    UserMenu
  },
  mounted() {
    // Auth.currentAuthenticatedUser({
    //   bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    // }).then(user => {
    //   console.log("User logged in ", user);
    // });
  },
  computed: {
    ...mapGetters("cart", ["badgeCount"]),
    ...mapGetters("auth", ["currentUserData"]),
    ...mapState("createstore", ["storeCategories"]),
    menu: {
      get() {
        return this.$store.getters["header/menu"];
      },
      set(newValue) {
        console.log("New value", newValue);
      }
    }
  },
  methods: {
    ...mapMutations("cart", ["SHOW_CART"]),
    logout() {
      this.showSubmenu = false;
      this.$store.dispatch("auth/logout");
    },
    hideusermenu() {
      this.showSubmenu = false;
    },

    hideCart() {
      this.SHOW_CART(false);
    }
  }
};
</script>
<style lang="postcss" scoped>
.advanced-enter-active {
  transition: all 0.3s ease-out;
}
.advanced-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.advanced-enter, .advanced-leave-to
/* .cart-leave-active below version 2.1.8 */ {
  transform: translatey(-25%);
  opacity: 0;
}
</style>
