import { API_URL } from "../../app-axios.js";

const api_path = "/offices";

const state = {
  offices: [],
  socialNetworks: [],
  items: []
};

// getters
const getters = {
  menu: state => {
    return {
      offices: state.offices,
      socialNetworks: state.socialNetworks
    };
  }
};

// actions
const actions = {
  getOffices({ commit }) {
    API_URL.get(api_path)
      .then(response => commit("SET_OFFICES", response))
      .catch(error => console.log("error", error));
  },
  getSocialNetworks({ commit }) {
    API_URL.get("/socialNetworks")
      .then(response => commit("SET_SOCIAL_NETWORKS", response))
      .catch(error => console.log("error", error));
  }
};

// mutations
const mutations = {
  SET_OFFICES(state, data) {
    state.offices = data.data;
  },
  SET_SOCIAL_NETWORKS(state, data) {
    state.socialNetworks = data.data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
